.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}


.accordion{
  width: 60%;
}

.course {
  background: rgba(211, 211, 211, 0.7); /* Light grey with transparency */
  border-radius: 15px; /* Rounded corners */
  margin-bottom: 10px; /* Increased margin for spacing */
  padding: 15px 20px; /* More padding for a better look */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.course:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Shadow effect on hover */
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 1.2rem; /* Slightly larger font size */
  font-weight: bold; /* Bold for emphasis */
}
.content{
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}
.content.show{
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
}

@media only screen and (max-width: 600px) {
  .title{
    font-size: 12px;
  }
}
