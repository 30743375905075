.popUpContainer{
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 100px;
  width: 100%;
  height: 100vh;
  z-index: 10;
}

.popUpContent{
  width: 70%;
  min-height: 400px;
  background-color: rgb(242, 243, 245);
  border: 1px solid black;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  overflow: auto;
}

.closePopUpButton{
  cursor: pointer;
  border: none;
  background-color: rgb(242, 243, 245);
  z-index: 100;
  font-size: large;
}