/* Use relative units for better responsiveness */

.aboutme {
  width: 100%;
  max-width: 1200px; /* Set a maximum width for larger screens */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some spacing around the content */
  box-sizing: border-box; /* Include padding and borders in the total width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
}

.aboutme h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2.5rem; /* Use relative units for font size */
  margin: 0;
}

.aboutme p {
  width: 100%; /* Allow paragraphs to take full container width */
  max-width: 800px; /* Set a maximum width for text */
  font-size: 1rem; /* Use relative units for font size */
  margin: 0;
}

a {
  color: black;
  font-weight: bold;
  /* text-decoration: none; Remove underlines from links */
}

/* Add media queries for responsiveness */
@media screen and (max-width: 768px) {
  .aboutme h2 {
    font-size: 1.8rem;
  }
  .aboutme p {
    font-size: 0.9rem;
  }
}
