.project {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.project h1 {
  margin-top: 50px;
  color: black;
  font-size: 35px;
}
.project img {
  width: 500px;
  height: 400px;
  border-radius: 10px;
  object-fit: contain;
}

.project p {
  font-size: 30px;
  color: black;
}

.project svg {
  font-size: 60px;
  color: black;
  cursor: pointer;
}

.project a{
  margin-left: 10px;
  font-size: 30px;
  font-weight: 400;
  background: black;
  border: none;
  border-radius: 50px;
  padding: 8px 10px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.links{
  display: flex;
  flex-direction: row;
  justify-content: center;
}