.home {
  width: 100%;
  align-items: center;
  font-family: "Arial", sans-serif;
  color: black;
}

.about {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  color: #f0f0f0;
  word-wrap: break-word;
}
 
.about h2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 60px;
  color: white;
  height: 50px;
}

.about .prompt {
  width: 40%;
  font-size: 25px;
}

.prompt svg {
  font-size: 60px;
  margin: 5px;
  color: white; /* Icon color */
  cursor: pointer;
  border-radius: 50%; /* Circular background */
  padding: 10px; /* Padding around icons */
  transition: background 0.3s ease, transform 0.3s ease; /* Smooth transition */
}
.prompt svg:hover {
  background: rgba(255, 255, 255, 0.5); /* Change background color on hover */
  transform: translateY(-3px); /* Lift effect on hover */
}
.prompt a {
  font-size: 30px;
  font-weight: 400;
  background: white; /* Background color */
  border: none;
  border-radius: 50px; /* Rounded corners */
  padding: 10px 20px; /* Adjusted padding for a rectangular shape */
  cursor: pointer;
  color: black;
  text-decoration: none;
  transition: background 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.prompt a:hover {
  background: rgba(255, 255, 255, 0.7); /* Lighter background on hover */
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}

.links{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: font-size 0.3s ease;
}
.prompt svg:hover {
  background: rgba(255, 255, 255, 0.5); /* Change background color on hover */
  transform: translateY(-3px); /* Lift effect on hover */
}

.skills {
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.skills h1{
  color: black;
  font-size: 3rem;
}

.skills .list {
  list-style: none;
  width: 60%;
}

.list span {
  font-size: 20px;
}

@media only screen and (max-width: 1070px) {
  .about h2 {
    font-size: 35px;
  }

  .about .prompt {
    margin-top: 10px;
    font-size: 20px;
    width: 80%;
  }

  .prompt a, svg{
    font-size: 20px;
  }
  .links a {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .skills {
    text-align: center;
  }
  .list {
    padding: 0;
  }
  .skills h2 {
    font-size: 30px;
  }
  .links a {
    font-size: 1rem;
  }
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust the gap between boxes as needed */
  width: 60%;
  margin: auto; /* Center the grid */
  margin-bottom: 2rem;
}

.skill-box {
  background-color: #f0f0f0; /* Change background color as needed */
  border-radius: 15px; /* Rounded corners */
  padding: 20px; /* Padding inside the box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.skill-box:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow effect on hover */
}
.skill-box h2 {
  margin-bottom: 10px; 
  font-size: 2rem;
}

.skill-box span {
  margin-bottom: 10px; 
  font-size: 1rem;
}