.navbar {
  width: 100%;
  height: 100px;
  background: black;
  align-items: center;

  position: fixed;
  top: 0px;
  z-index: 1000;
}

.links {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
  overflow: hidden;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin: 0; /* Remove the margin */
  flex: 1; /* Distribute available space evenly among links */
  text-align: center;
  font-size: 25px;
}

.toggleButton {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton svg {
  font-size: 50px;
}
.toggleButton button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 30px;
  height: 50vh;
}

#open a {
  width: 100%;
  text-align: center;
}


@media only screen and (max-width: 900px) {
  .navbar a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
  }

  #open {
    height: 100px;
  }

  #open .links {
    flex-direction: row
  }
  #open .links a{
    height: 30%;
  }
}